import { Auth } from "@aws-amplify/auth";
import { Hub, HubCapsule } from "@aws-amplify/core";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";

/**
 * ログイン中のユーザーの情報
 */
export class UserData {
    #session: CognitoUserSession;
    #user: CognitoUser;

    /**
     * 新しい {@link UserData} インスタンスを初期化します。
     *
     * @param session セッション
     * @param user ユーザー
     */
    constructor(session: CognitoUserSession, user: CognitoUser) {
        this.#session = session;
        this.#user = user;
    }

    /**
     * アクセストークン
     */
    get accessToken(): string {
        return this.#session.getAccessToken().getJwtToken();
    }

    /**
     * アカウント ID (?)
     *
     * @deprecated ユーザー名の `@` より左側です。メールアドレスによるログインに
     * 切り替える前の名残では?
     */
    get accountId(): string {
        return this.username.split("@")[0];
    }

    /**
     * 実験的機能の利用可能フラグ
     */
    get allowExperimental(): boolean {
        return this.groups.includes("experimental");
    }

    /**
     * メールアドレス
     */
    get email(): string {
        const { payload } = this.#session.getIdToken();
        return payload.email ?? "";
    }

    /**
     * 所属する Cognito グループの名前の配列
     */
    get groups(): readonly string[] {
        const { payload } = this.#session.getIdToken();
        return payload["cognito:groups"] ?? [];
    }

    /**
     * ID トークン
     */
    get idToken(): string {
        return this.#session.getIdToken().getJwtToken();
    }

    /**
     * 管理者フラグ
     */
    get maintenance(): boolean {
        return this.groups.includes("maintenance");
    }

    /**
     * 名前
     */
    get name(): string {
        const { payload } = this.#session.getIdToken();
        return payload.name ?? "";
    }

    /**
     * ユーザー ID
     */
    get sub(): string {
        const { payload } = this.#session.getIdToken();
        return payload.sub ?? "";
    }

    /**
     * ユーザー ID (?)
     *
     * @deprecated ユーザー名の `@` より右側です。メールアドレスによるログインに
     * 切り替える前の名残では?
     */
    get userId(): string {
        return this.username.split("@")[1] ?? "";
    }

    /**
     * Cognito ユーザー名 (メールアドレス)
     *
     * @deprecated {@link email} か {@link sub} をご利用ください。
     */
    get username(): string {
        const { payload } = this.#session.getIdToken();
        return payload["cognito:username"];
    }

    /**
     * ID トークンとアクセストークンを更新します。
     *
     * デフォルトでは、トークンが期限切れを起こしていた場合のみ更新します。
     * 強制的に更新する場合は `force` フラグを `true` に設定してください。
     *
     * トークンを更新すると、{@link UserData} インスタンスは役に立たなくなります。
     * {@link useUserData} フックを利用している場合は {@link UserData} インスタ
     * ンスが再作成されるので、以後は新しいインスタンスを利用してください。
     *
     * @param force 強制的にリフレッシュするフラグ
     */
    async refreshTokens(force = false): Promise<void> {
        await (force
            ? refreshSession(this.#user, this.#session)
            : getSession(this.#user));
    }
}

/**
 * ログイン中ユーザーの情報を使用します。
 *
 * @returns ログイン中ユーザーの情報
 */
export function useUserData(): UserData {
    const userData = useContext(UserDataContext);
    if (userData === undefined) {
        throw new Error("UserData not found.");
    }
    return userData;
}

export namespace UserDataProvider {
    /**
     * {@link UserDataProvider} コンポーネントのプロパティ定義
     */
    export interface Props {
        /** 子要素 */
        readonly children?: Children | undefined;
    }

    export interface Children {
        readonly authenticated?: ReactNode | undefined;
        readonly loading?: ReactNode | undefined;
        readonly unauthenticated?: ReactNode | undefined;
    }
}

/**
 * ユーザー情報を提供するプロバイダ コンポーネントです。
 *
 * @param props プロパティ
 * @returns 描画内容
 */
export function UserDataProvider({
    children,
}: UserDataProvider.Props): JSX.Element {
    const [userData, setUserData] = useState<UserData>();
    const [error, setError] = useState<unknown>();
    const { loading, authenticated, unauthenticated } = children ?? {};

    useEffect(() => {
        let unmounted = false;

        function update(): void {
            Auth.currentAuthenticatedUser()
                .then((user: CognitoUser) =>
                    Promise.all([
                        user,
                        unmounted ? undefined : getSession(user),
                    ]),
                )
                .then(([user, session]) => {
                    if (unmounted || session === undefined) {
                        return;
                    }
                    setError(undefined);
                    setUserData(new UserData(session, user));
                })
                .catch((error: unknown) => {
                    if (unmounted) {
                        return;
                    }
                    setError(error);
                    setUserData(undefined);
                });
        }

        function listener(data: HubCapsule) {
            switch (data.payload.event) {
                case "signIn":
                case "signUp":
                case "signOut":
                case "signIn_failure":
                case "tokenRefresh":
                case "tokenRefresh_failure":
                case "configured":
                    update();

                // no default
            }
        }

        update();

        Hub.listen("auth", listener);
        return () => {
            unmounted = true;
            Hub.remove("auth", listener);
        };
    }, []);

    return React.createElement(
        UserDataContext.Provider,
        { value: userData },
        error !== undefined
            ? unauthenticated
            : userData !== undefined
            ? authenticated
            : loading,
    );
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const UserDataContext = createContext<UserData | undefined>(undefined);

/**
 * 強制的にセッションを更新 (更新トークンによる再認証) します。
 *
 * @param user ユーザーオブジェクト
 * @param session セッションオブジェクト
 */
function refreshSession(
    user: CognitoUser,
    session: CognitoUserSession,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        user.refreshSession(session.getRefreshToken(), (error) => {
            if (error != null) {
                reject(error);
            } else {
                resolve();
            }
        });
    });
}

/**
 * セッション オブジェクトを取得します。
 *
 * このとき、既存のセッションが期限切れしていた場合は更新 (更新トークンによる再
 * 認証) します。
 *
 * @param user ユーザーオブジェクト
 * @returns セッションオブジェクト
 */
function getSession(user: CognitoUser): Promise<CognitoUserSession> {
    return new Promise<CognitoUserSession>((resolve, reject) => {
        user.getSession(
            (error: Error | null, session: CognitoUserSession | null) => {
                if (session == null) {
                    reject(error);
                } else {
                    resolve(session);
                }
            },
        );
    });
}
